<template>
  <el-dialog
    :title="$t('el.order.changeRefundAmount')"
    :visible.sync="visible"
    append-to-body
    width="800px"
  >
    <xm-table
      ref="xmTable"
      :hideForm="true"
      :fatch-method="fatchMethod"
      :showPagination="false"
      :showTotal="false"
      :columns="columns"
      noEmptyText="el.common.noData"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-input-number
          v-model="scope.row.realAmount"
          :min="0"
          :max="scope.row.coursePrice || 0"
          :step="0.01"
          :step-strictly="true"
          :controls="false"
        ></el-input-number>
      </template>
    </xm-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t("el.MyHome.Cancel") }}</el-button>
      <el-button type="primary" @click="confirm">{{
        $t("el.MyHome.Sure")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { handparams } from "@/utils";
export default {
  name: "",
  components: {},
  props: {
    copyedRefundDetailCourseDTOList: {
      type: Array,
    },
    refundAmount: {
      type: Number,
    },
    // refundVerifyDetailDTOList: {
    //   type: Array,
    // },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t("el.order.orderItemNo"),
          prop: "orderItemNo",
        },
        {
          label: this.$t("el.order.courseName"),
          prop: "courseName",
        },
        // {
        //   label: this.$t("el.planCourse.courseSource"),
        //   prop: "courseSource",
        // },
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "originTenantName",
        },
        {
          label: this.$t("el.planCourse.courseClass"),
          prop: "classCourseName",
        },
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "categoryName",
        },
        {
          label: this.$t("el.planCourse.coursePrice"),
          prop: "coursePrice",
        },
        {
          label: this.$t("el.order.tuikuanjine") + this.$t("el.order.yuan"),
          prop: "realAmount",
          slotName: "otherHandle",
        },
      ];
    },
  },
  data() {
    return {
      visible: false,
      copyedCopyedRefundDetailCourseDTOList: [],
    };
  },

  created() {},
  mounted() {},
  methods: {
    open() {
      this.visible = true;
      this.copyedCopyedRefundDetailCourseDTOList = JSON.parse(
        JSON.stringify(this.copyedRefundDetailCourseDTOList)
      );

      this.$nextTick(() => {
        this.$refs.xmTable.query();
      });
    },
    // 获取列表数据
    fatchMethod(params) {
      return new Promise((resolve) =>
        resolve({
          data: { records: this.copyedCopyedRefundDetailCourseDTOList },
        })
      );
    },
    confirm() {
      let data = this.$refs.xmTable.getNowDataList();
      if (data.some((item) => !item.realAmount && item.realAmount !== 0)) {
        this.$message.error(this.$t("el.order.emptyNotice"));
        return;
      }
      let sum = 0;
      //   let refundVerifyDetailDTOList = [];
      this.copyedCopyedRefundDetailCourseDTOList.map((i) => {
        sum += i.realAmount * 100;
        // refundVerifyDetailDTOList.push({
        //   orderItemNo: i.orderItemNo,
        //   realAmount: i.realAmount,
        // });
      });
      sum *= 0.01;
      if (sum === 0) {
        this.$message.error(this.$t("el.order.zeroNotice"));
        return;
      }
      this.$emit("update:refundAmount", sum);
      this.$emit(
        "update:copyedRefundDetailCourseDTOList",
        this.copyedCopyedRefundDetailCourseDTOList
      );
      this.$emit("computeRefundVerifyDetailDTOList");
      this.close();
    },

    close() {
      this.visible = false;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped></style>
