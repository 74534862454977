<template>
  <div class="refund-detail">
    <div class="refund-detail-top f-c">
      <div class="f-l refund-detail-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="refund-detail-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l refund-detail-top-title">
        {{ $t("el.common.orderManagement") }} >
        {{ $t("el.order.refundDetail") }}
      </div>
      <el-button
        class="f-r"
        size="medium"
        type="primary"
        v-if="detailInfo.refundDetailInfoDTO.verifyStatus == 1"
        @click="$refs.DialogRefundDetailAuditRef.open()"
        >&nbsp;&nbsp;{{
          $t("el.schoolResourceManage.audit")
        }}&nbsp;&nbsp;</el-button
      >
      <!-- <span class="f-r" @click="$refs.DialogRefundDetailAuditRef.open()"
        >开发时用的打开窗口</span
      > -->
      <DialogRefundDetailAudit
        ref="DialogRefundDetailAuditRef"
        :refundDetailCourseDTOList="detailInfo.refundDetailCourseDTOList"
        :refundId="$route.query.refundId"
        @fresh="$refs.xmTable.query()"
      />
    </div>

    <div class="detail-info">
      <el-descriptions :title="$t('el.order.refundInfo')">
        <el-descriptions-item :label="$t('el.order.refundNo')">{{
          detailInfo.refundDetailInfoDTO.refundNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.applyTime')">{{
          detailInfo.refundDetailInfoDTO.applyTime
            ? $utils.getFormatterDate(
                new Date(detailInfo.refundDetailInfoDTO.applyTime)
              ) +
              " " +
              $utils.getFormatterTime(
                new Date(detailInfo.refundDetailInfoDTO.applyTime)
              )
            : "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.shenqingtuikuanjine')"
          >¥{{ detailInfo.refundDetailInfoDTO.amount }}</el-descriptions-item
        >
        <el-descriptions-item :label="$t('el.order.refundReason')">{{
          detailInfo.refundDetailInfoDTO.refundReason
        }}</el-descriptions-item>
        <el-descriptions-item
          :label="$t('el.schoolResourceManage.auditStatus')"
          >{{
            $store.state.dict.VERITY_STATUS.map((el) => el.dictionaryText)[
              detailInfo.refundDetailInfoDTO.verifyStatus - 1
            ] || "-"
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.verifyStatus != 1 &&
            detailInfo.refundDetailInfoDTO.verifyOperatorName
          "
          :label="$t('el.schoolResourceManage.auditor')"
          >{{
            detailInfo.refundDetailInfoDTO.verifyOperatorName
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.verifyStatus != 1 &&
            detailInfo.refundDetailInfoDTO.verifyTime
          "
          :label="$t('el.schoolResourceManage.auditTime')"
          >{{
            detailInfo.refundDetailInfoDTO.verifyTime
              ? $utils.getFormatterDate(
                  new Date(detailInfo.refundDetailInfoDTO.verifyTime)
                ) +
                " " +
                $utils.getFormatterTime(
                  new Date(detailInfo.refundDetailInfoDTO.verifyTime)
                )
              : "-"
          }}</el-descriptions-item
        >
        <el-descriptions-item :label="$t('el.order.refundStatus')">{{
          $store.state.dict.REFUND_STATUS.map((el) => el.dictionaryText)[
            detailInfo.refundDetailInfoDTO.refundStatus - 1
          ]
        }}</el-descriptions-item>
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.verifyStatus == 2 &&
            detailInfo.refundDetailInfoDTO.realAmount
          "
          :label="$t('el.order.shijituikuanjine')"
          >¥{{
            detailInfo.refundDetailInfoDTO.realAmount
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.verifyStatus == 2 &&
            detailInfo.refundDetailInfoDTO.refundTime
          "
          :label="$t('el.order.refundTime')"
          >{{
            detailInfo.refundDetailInfoDTO.refundTime
              ? $utils.getFormatterDate(
                  new Date(detailInfo.refundDetailInfoDTO.refundTime)
                ) +
                " " +
                $utils.getFormatterTime(
                  new Date(detailInfo.refundDetailInfoDTO.refundTime)
                )
              : "-"
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.refundStatus == 4 &&
            detailInfo.refundDetailInfoDTO.refundEditReason
          "
          :label="$t('el.order.changeReason')"
          >{{
            detailInfo.refundDetailInfoDTO.refundEditReason
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="
            detailInfo.refundDetailInfoDTO.verifyStatus == 3 &&
            detailInfo.refundDetailInfoDTO.verifyOpinion
          "
          :label="$t('el.order.failReason')"
          >{{
            detailInfo.refundDetailInfoDTO.verifyOpinion
          }}</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions :title="$t('el.order.orderInfo')">
        <el-descriptions-item :label="$t('el.order.orderNo')">{{
          detailInfo.refundDetailOrderDTO.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.createTime')">{{
          detailInfo.refundDetailOrderDTO.createTime
            ? $utils.getFormatterDate(
                new Date(detailInfo.refundDetailOrderDTO.createTime)
              ) +
              " " +
              $utils.getFormatterTime(
                new Date(detailInfo.refundDetailOrderDTO.createTime)
              )
            : "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.userPhoneNumber')">{{
          detailInfo.refundDetailOrderDTO.accountPhone
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.orderStatus')">{{
          $store.state.dict.ORDER_STATUS.map((el) => el.dictionaryText)[
            detailInfo.refundDetailOrderDTO.orderStatus - 1
          ]
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.dingdanjine')"
          >¥{{ detailInfo.refundDetailOrderDTO.amount }}</el-descriptions-item
        >
        <el-descriptions-item :label="$t('el.order.shifujine')">{{
          detailInfo.refundDetailOrderDTO.realAmount === null ||
          detailInfo.refundDetailOrderDTO.realAmount === undefined
            ? "-"
            : "¥" + detailInfo.refundDetailOrderDTO.realAmount
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.payBy')">
          {{ detailInfo.refundDetailOrderDTO.payType || "-" }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.payTime')">{{
          detailInfo.refundDetailOrderDTO.payTime
            ? $utils.getFormatterDate(
                new Date(detailInfo.refundDetailOrderDTO.payTime)
              ) +
              " " +
              $utils.getFormatterTime(
                new Date(detailInfo.refundDetailOrderDTO.payTime)
              )
            : "-"
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.shoukuanshanghu')">{{
          detailInfo.refundDetailOrderDTO.paymentMerchant
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :title="$t('el.order.registeredStudents')">
        <el-descriptions-item :label="$t('el.courseClassManage.studentName')">{{
          detailInfo.refundDetailStudentDTO.studentName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.order.studentNo')">{{
          detailInfo.refundDetailStudentDTO.studentNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.schoolResourceManage.school')">{{
          detailInfo.refundDetailStudentDTO.schoolName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.planCourse.inGrade')">{{
          detailInfo.refundDetailStudentDTO.gradeName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('el.courseClassManage.InClass')">{{
          detailInfo.refundDetailStudentDTO.className
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :title="$t('el.order.courseInfo')"></el-descriptions>

      <xm-table
        ref="xmTable"
        :hideForm="true"
        :fatch-method="fatchMethod"
        :columns="columns"
        :showPagination="false"
        :showTotal="false"
        noEmptyText="el.common.noData"
      >
      </xm-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  refundDetailInfoApi,
  refundYearApi,
  refundClassApi,
} from "@/api/manage/order";
import { handparams } from "@/utils";
import DialogRefundDetailAudit from "./DialogRefundDetailAudit.vue";

export default {
  components: { DialogRefundDetailAudit },
  computed: {
    ...mapGetters({
      termOptionsOrigin: "termOptions",
      schoolYearOptionsOrigin: "schoolYearOptions",
    }),
    ...mapState({
      tenantId: (state) => state.schoolCode,
    }),
    // 学期下拉
    termOptions() {
      return [
        { value: "", label: this.$t("el.planCourse.all") },
        ...this.termOptionsOrigin,
      ];
    },
    // 学年下拉
    schoolYearOptions() {
      return [
        { value: "", label: this.$t("el.planCourse.all") },
        ...this.schoolYearOptionsOrigin,
      ];
    },
    columns() {
      return [
        {
          label: this.$t("el.order.orderItemNo"),
          prop: "orderItemNo",
        },
        {
          label: this.$t("el.order.courseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.planCourse.courseSource"),
          prop: "courseSource",
        },
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "originTenantName",
        },
        {
          label: this.$t("el.planCourse.courseClass"),
          prop: "classCourseName",
        },
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "categoryName",
        },
        {
          label: this.$t("el.order.shifujine") + this.$t("el.order.yuan"),
          prop: "coursePrice",
          formatter: (row, column, value) => {
            return value === null || value === undefined ? "-" : value;
          },
        },
        {
          label: this.$t("el.order.tuikuanjine") + this.$t("el.order.yuan"),
          prop: "refundAmount",
          formatter: (row, column, value) => {
            return value === null || value === undefined ? "-" : value;
          },
        },
      ];
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/images/default-image.png"), // 默认封面
      detailInfo: {
        refundDetailInfoDTO: {},
        refundDetailOrderDTO: {},
        refundDetailStudentDTO: {},
        refundDetailCourseDTOList: [],
      },
      courseClassOptions: [], // 课程班级下拉项
      // schoolYearOptions: [],
    };
  },
  mounted() {
    // this.handleGetCourseDetail();
    // this.handleGetClass();
    // this.fatchMethod();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // // 获取课程详情数据
    // handleGetCourseDetail() {
    //   refundDetailInfoApi(this.$route.query.refundId).then((res) => {
    //     this.detailInfo = res?.data || {};
    //   });
    // },
    // 获取列表数据
    async fatchMethod(params) {
      //   const data = handparams({
      //     ...params,
      //     refundId: this.$route.query.refundId,
      //   });
      //   delete data._orderTime;
      let result = await refundDetailInfoApi(this.$route.query.refundId);
      if (result.data) {
        if (!result.data.refundDetailOrderDTO)
          result.data.refundDetailOrderDTO = {};
        this.detailInfo = result.data;

        return new Promise((resolve) => {
          resolve({
            data: {
              records: result.data.refundDetailCourseDTOList,
            },
          });
        });
      }
    },
    // 获取列表内的全部学年
    // handleGetYear() {
    //   refundYearApi(this.tenantId).then((res) => {
    //     this.schoolYearOptions = [
    //       { value: "", label: "全部" },
    //       ...(res?.data || []).map(({ id, name }) => ({
    //         value: id,
    //         label: name,
    //       })),
    //     ];
    //   });
    // },
    // 获取列表内的全部班级
    // handleGetClass() {
    //   refundClassApi(this.tenantId, this.$route.query.refundId).then((res) => {
    //     this.courseClassOptions = [
    //       ...(res?.data || []).map(({ id, name }) => ({
    //         value: id,
    //         label: name,
    //       })),
    //     ];
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.refund-detail {
  padding: 18px 16px 50px;
  background-color: #fff;
  border-radius: 10px;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
  }

  .detail-info {
    margin: 25px 0 30px;
    .details-header-icon {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      margin-left: 9px;
    }
    .details-course-info {
      margin-left: 21px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      display: flex;
      flex-direction: column;
      .details-course-name {
        font-size: 18px;
        font-weight: 500;
        color: #131313;
        margin-bottom: 20px;
      }
      .details-course-desc {
        &.info {
          display: flex;
          flex-wrap: wrap;
          .details-desc-item {
            margin-bottom: 10px;
          }
        }
        & + .details-course-desc {
          padding-top: 10px;
        }
        .details-desc-item {
          margin-right: 63px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  /deep/ .content-cell-wrap {
    .cell {
      text-overflow: unset;
      -webkit-line-clamp: unset;
      white-space: normal;
    }
  }
}
</style>
