<template>
  <el-dialog
    :title="$t('el.order.refundAudit')"
    :visible.sync="visible"
    v-loading="loading"
  >
    <el-form :model="form" ref="form" label-width="100px" class="demo-form">
      <el-form-item :label="$t('el.order.auditResult')" prop="verifyStatus">
        <el-radio-group v-model="form.verifyStatus">
          <el-radio :label="2">{{ $t("el.order.pass") }}</el-radio>
          <el-radio :label="3">{{ $t("el.order.noPass") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('el.order.tuikuanjine')" prop="refundAmount">
        <span> ¥{{ refundAmount }} </span>
        <el-button
          type="text"
          size="medium"
          @click="$refs.DialogRefundDetailAuditChangeAmountRef.open()"
          >{{ $t("el.classroomWps.modify") }}</el-button
        >
        <DialogRefundDetailAuditChangeAmount
          ref="DialogRefundDetailAuditChangeAmountRef"
          :copyedRefundDetailCourseDTOList.sync="
            copyedRefundDetailCourseDTOList
          "
          :refundAmount.sync="refundAmount"
          @computeRefundVerifyDetailDTOList="computeRefundVerifyDetailDTOList"
        />
      </el-form-item>
      <el-form-item
        v-if="form.verifyStatus == 2 && refundAmount != 申请金额"
        :label="$t('el.order.changeReason')"
        prop="refundEditReason"
        :rules="[
          {
            required: true,
            message: $t('el.common.pleaseEnter'),
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          type="textarea"
          v-model="form.refundEditReason"
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="form.verifyStatus == 3"
        :label="$t('el.order.reason')"
        prop="verifyOpinion"
        :rules="[
          {
            required: true,
            message: $t('el.common.pleaseEnter'),
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          type="textarea"
          v-model="form.verifyOpinion"
          maxlength="200"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{
        $t("el.MyHome.Cancel")
      }}</el-button>
      <el-button type="primary" @click="submit">{{
        $t("el.MyHome.Sure")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import DialogRefundDetailAuditChangeAmount from "./DialogRefundDetailAuditChangeAmount.vue";
import { refundAudit } from "@/api/manage/order";
export default {
  name: "",
  components: { DialogRefundDetailAuditChangeAmount },
  props: {
    refundDetailCourseDTOList: {
      type: Array,
    },
    refundId: {
      type: [Number, String],
    },
  },
  computed: {
    申请金额() {
      let sum = 0;
      this.refundDetailCourseDTOList.map((i) => {
        sum += i.coursePrice * 100;
      });
      return sum * 0.01;
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      refundAmount: 0,
      form: {
        verifyStatus: 2,
        refundEditReason: "",
        verifyOpinion: "",
      },
      copyedRefundDetailCourseDTOList: [],

      refundVerifyDetailDTOList: [], // orderItemNo: realAmount
    };
  },
  created() {},
  mounted() {},
  methods: {
    open() {
      this.refundAmount = this.申请金额;
      this.copyedRefundDetailCourseDTOList = JSON.parse(
        JSON.stringify(this.refundDetailCourseDTOList)
      );
      this.copyedRefundDetailCourseDTOList.forEach((i) => {
        this.$set(i, "realAmount", i.coursePrice);
      });
      this.refundVerifyDetailDTOList = [];

      this.visible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.form.verifyStatus == 2 &&
            !this.refundVerifyDetailDTOList.length
          ) {
            this.computeRefundVerifyDetailDTOList();
          }
          this.loading = true;
          refundAudit({
            refundId: this.refundId,
            verifyStatus: this.form.verifyStatus,
            refundAmount: this.refundAmount,
            refundEditReason: this.form.refundEditReason,
            verifyOpinion: this.form.verifyOpinion,
            refundVerifyDetailDTOList: this.refundVerifyDetailDTOList,
          })
            .then((res) => {
              this.$message.success(
                this.$t("el.order.refundAudit") +
                  (this.form.verifyStatus == 2
                    ? this.$t("el.order.passed")
                    : this.$t("el.order.noPassed"))
              );
              this.$refs.form.resetFields();
              this.visible = false;
              this.$emit("fresh");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    computeRefundVerifyDetailDTOList() {
      this.refundVerifyDetailDTOList = [];
      this.copyedRefundDetailCourseDTOList.map((i) => {
        this.refundVerifyDetailDTOList.push({
          orderItemNo: i.orderItemNo,
          realAmount: i.realAmount,
        });
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #6049ff;
  background: #6049ff;
}
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #6049ff;
}
/deep/ .el-dialog__header {
  text-align: left;
}
/deep/ .el-dialog__body {
  text-align: left;
}
</style>
